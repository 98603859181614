/* eslint-disable sonarjs/no-identical-functions */
import { FC } from "react";
import { Stack } from "@csis.com/components";
import { DocumentationSection } from "../Section/types";
import { capitalizeFirstLetter, getSectionId } from "../content/utils/utils";

const MenuGroup: FC<{
  header: string;
  isHeaderTopLevel?: boolean;
  sectionPortalsId?: string;
  // Remove internal prop when the page is no longer internal
  internal?: boolean;
}> = ({
  header,
  isHeaderTopLevel = false,
  children,
  sectionPortalsId,
  internal,
}) => {
  const headerId = `#${header.toLowerCase().replace(" ", "-")}`;

  // if sectionPortalsId is provided, use that as the href
  const hrefId =
    sectionPortalsId !== undefined ? "#" + sectionPortalsId : headerId;

  // if sectionPortalsId is provided, use that as the aria-label
  const headerAriaLabel =
    sectionPortalsId !== undefined ? sectionPortalsId : header;

  return (
    <Stack isVertical>
      {isHeaderTopLevel ? (
        <a href={hrefId}>
          <Stack>
            <h2 className="f_semibold f_csis f_large">{header}</h2>
            {/* Remove this INTERNAL div when the page is no longer internal */}
            {internal && (
              <div
                className="f_small f_semibold f_orange"
                title="Page only visible internally"
              >
                INTERNAL
              </div>
            )}
          </Stack>
        </a>
      ) : (
        <a href={hrefId} className="f_semibold">
          {header}
        </a>
      )}
      <div className="help-and-support-page__menu-group">
        <nav aria-label={headerAriaLabel.toLowerCase()}>
          <Stack isVertical>{children}</Stack>
        </nav>
      </div>
    </Stack>
  );
};

export const HelpSideBar = ({
  sections,
  isAdmin,
}: {
  sections?: DocumentationSection[];
  isAdmin?: boolean;
}) => (
  <nav aria-label="api documentation sidebar">
    <Stack isVertical gutterSize="huge">
      <MenuGroup header="eCrime APIs" isHeaderTopLevel>
        <Stack isVertical>
          <MenuGroup header="Gaining Access">
            <a href="#token-authentication">Token Authentication</a>
          </MenuGroup>

          <MenuGroup header="Alerts">
            <a href="#list-subscribed-alerters">List subscribed Alerters</a>
            <a href="#alert-detail">Alert Detail</a>
            <a href="#alert-search">Alert Search</a>
          </MenuGroup>

          <MenuGroup header="Compromised Data">
            <a href="#list-compromised-payment-card-reports">
              List compromised payment card reports
            </a>
            <a href="#list-compromised-credential-reports">
              List compromised credential reports
            </a>
          </MenuGroup>

          <MenuGroup header="Tickets">
            <a href="#list-all-tickets">List all Tickets</a>
            <a href="#view-single-ticket">View single Ticket</a>
            <a href="#ticket-thread">Ticket Thread</a>
          </MenuGroup>

          <MenuGroup header="Phishing">
            <a href="#check-if-a-url-is-in-our-records">
              Check if a url is in our records
            </a>
            <a href="#check-if-a-domain-is-in-our-records">
              Check if a domain is in our records
            </a>
            <a href="#check-if-a-hostname-is-in-our-records">
              Check if a hostname is in our records
            </a>
            <a href="#get-stats-for-a-url">Get stats for a URL</a>
            <a href="#submit-a-url">Submit a URL</a>
            <a href="#get-all-urls">Get all URLs</a>
            <a href="#specific-url-contents">Specific URL contents</a>
          </MenuGroup>
        </Stack>
      </MenuGroup>

      {isAdmin && sections && renderGeneratedMenuGroups(sections)}
    </Stack>
  </nav>
);

const renderGeneratedMenuGroups = (sections: DocumentationSection[]) =>
  sections.map((section) => {
    if (!section) return null;

    const sectionId = getSectionId(section.sectionName);

    return (
      <MenuGroup
        header={section.sectionName || "Missing Section Name"}
        key={section.sectionName}
        isHeaderTopLevel
        // Remove internal prop when the page is no longer internal
        internal={true}
      >
        {Array.from(section.tags).map((tag) => {
          const capitalizedTag = capitalizeFirstLetter(tag);

          return (
            <Stack key={tag}>
              <MenuGroup
                sectionPortalsId={`${sectionId}-${tag}`}
                header={capitalizedTag}
                key={tag}
              >
                {
                  // "!" needed here. already tested for above. ts hello!?
                  section.paths.map((path) =>
                    path.operations.map((operation) => {
                      if (operation.tags?.join("-") !== tag) return null;
                      return (
                        <a
                          href={`#${operation.operationId}`}
                          key={operation.operationId}
                        >
                          {operation.summary}
                        </a>
                      );
                    })
                  )
                }
              </MenuGroup>
            </Stack>
          );
        })}
      </MenuGroup>
    );
  });
