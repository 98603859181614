import { produce } from "immer";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  GenericFormError,
  Grid,
  GridItem,
  InputFile,
  Questionnaire,
  Stack,
} from "@csis.com/components";
import { QuestionnaireData } from "@csis.com/components/src/atoms/Questionnaire/types";
import {
  emergencyCaseTypeLabels,
  emergencyCaseTypes,
  questionnaireKeys,
} from "../constants";
import {
  getEmergencyCaseInfoResult,
  getEmergencyCaseQuestionaryResult,
  updateEmergencyCaseAttachmentsResult,
} from "../selectors";
import {
  deleteEmergencyCaseAttachmentById,
  getEmergencyCaseAttachments,
  updateEmergencyCaseAttachments,
} from "../slice";
import { EmergencyCaseQuestionaryData, Question } from "../types";
import { getEmergencyCaseAttachments as convertAttachments } from "../utils";

interface EmergencyCaseQuestionaryProps {
  onSuccess: (formDetails: EmergencyCaseQuestionaryData) => void;
  submissionCode: string;
}
export const EmergencyCaseQuestionary = ({
  onSuccess,
  submissionCode,
}: EmergencyCaseQuestionaryProps) => {
  const [questionnaireData, setQuestionnaireData] = useState<QuestionnaireData>(
    {
      questions: [
        {
          id: questionnaireKeys.description_brief,
          type: "textarea",
          placeholder: "Briefly describe what happened",
          label: "Briefly describe what happened",
        },
        {
          id: questionnaireKeys.discovery_details,
          type: "textarea",
          placeholder: "How and when did you discover this?",
          label: "How and when did you discover this?",
        },
        {
          id: questionnaireKeys.affected_stuff,
          type: "textarea",
          placeholder: "What is affected? (servers, clients, network)",
          label: "What is affected? (servers, clients, network)",
        },
        {
          id: questionnaireKeys.done_already,
          type: "textarea",
          placeholder: "What has been done so far?",
          label: "What has been done so far?",
        },
        {
          id: questionnaireKeys.csis_engagement,
          type: "textarea",
          placeholder: "What would you like CSIS to do?",
          label: "What would you like CSIS to do?",
        },
      ],
    }
  );
  function handleAnswerChange(
    newAnswer: string | string[],
    questionId: string
  ) {
    if (questionnaireData.questions) {
      const questions = questionnaireData.questions;
      const questionIndex = questions.findIndex((q) => q.id === questionId);
      const currentQuestion = questions[questionIndex];
      if (currentQuestion) {
        const updatedQuestionnaire = produce(questionnaireData, (draft) => {
          if (draft.questions) {
            // @ts-ignore
            draft.questions[questionIndex].answer = newAnswer;
          }
        });
        setQuestionnaireData(updatedQuestionnaire);
      }
    }
  }

  const dispatch = useDispatch();
  const { emergencyCaseInfo } = useSelector(getEmergencyCaseInfoResult);

  const handleSubmitQuestionary = () => {
    const formDetails =
      questionnaireData?.questions?.reduce<EmergencyCaseQuestionaryData>(
        (acc, question) => {
          acc[question.id as Question] = question.answer as string;
          return acc;
        },
        {} as EmergencyCaseQuestionaryData
      );
    onSuccess(formDetails as EmergencyCaseQuestionaryData);
  };
  const {
    isEmergencyCaseQuestionaryDataPending,
    emergencyCaseQuestionaryDataError,
  } = useSelector(getEmergencyCaseQuestionaryResult);

  const {
    uploadedAttachments,
    isEmergencyCaseAttachmentsPending,
    emergencyCaseAttachmentsError,
  } = useSelector(updateEmergencyCaseAttachmentsResult);
  const showFileUpload =
    emergencyCaseInfo?.emergency_response_case_type ===
    emergencyCaseTypeLabels[emergencyCaseTypes.ot];

  const attachments = useMemo(() => {
    return convertAttachments(uploadedAttachments);
  }, [uploadedAttachments]);

  const handleFileUpload = (files: FileList) => {
    dispatch(
      updateEmergencyCaseAttachments({
        caseId: emergencyCaseInfo?.external_id || "",
        files: Array.from(files),
      })
    );
  };

  useEffect(() => {
    dispatch(
      getEmergencyCaseAttachments({
        caseId: emergencyCaseInfo?.external_id || "",
        submissionCode,
      })
    );
  }, [dispatch, emergencyCaseInfo?.external_id, submissionCode]);

  const handleFileRemove = (fileId: string) => {
    dispatch(
      deleteEmergencyCaseAttachmentById({
        caseId: emergencyCaseInfo?.external_id || "",
        submissionCode,
        attachmentId: fileId,
      })
    );
  };

  return (
    <Card isFullHeight hasShadow={false} spacing="huge" isFullWidth color="alt">
      <div className="emergency-case-confirmation-page__questionary">
        <Stack isExpanded isVertical gutterSize="gigantic" align="center">
          <Questionnaire
            questionnaire={questionnaireData}
            onAnswerChange={handleAnswerChange}
          />

          {showFileUpload && (
            <InputFile
              name="Emergency case questionary data file"
              onFilesUpload={handleFileUpload}
              maxFileSize={8}
              files={attachments}
              onFileRemove={handleFileRemove}
              errorText={emergencyCaseAttachmentsError}
              isPending={isEmergencyCaseAttachmentsPending}
            />
          )}

          {emergencyCaseQuestionaryDataError && (
            <GenericFormError errorText={emergencyCaseQuestionaryDataError} />
          )}
          <Grid justify="center">
            <GridItem span={9}>
              <Button
                formId="emergency-case-questionary"
                text={"SUBMIT"}
                isLoading={isEmergencyCaseQuestionaryDataPending}
                name="confirm-btn"
                size="small"
                onButtonClick={handleSubmitQuestionary}
                isFullWidth
              />
            </GridItem>
          </Grid>
        </Stack>
      </div>
    </Card>
  );
};
