/* eslint-disable sonarjs/no-duplicate-string */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { isNullOrUndefined } from "@csis.com/components/src/utils/utils";
import { axiosCsisApi } from "@csis.com/tip/src/App";
import { handleRequestError } from "@csis.com/tip/src/api/utils";
import { OtpInfo, OtpPostData } from "./OtpDialog/types";
import {
  changePasswordApi,
  createTokenApi,
  disableTokenApi,
  fetchOtpInfoApi,
  fetchProfileApi,
  generateOtpApi,
  patchProfileApi,
} from "./api/api";
import { OtpInfoResponse, ProfileResponse } from "./api/types";
import { getSelectedOrgId } from "./selectors";
import { PasswordData, Profile } from "./types";

interface StateSlice {
  profile: Profile | null;
  isFetchProfilePending: boolean;
  profileFetchError: string | null;
  profileFetchErrorStatus: number | null;

  isPatchProfilePending: boolean;
  profilePatchSuccess: boolean;
  profilePatchError: string | null;

  isPatchPasswordPending: boolean;
  passwordPatchSuccess: boolean;
  passwordPatchError: string | null;

  isCreateTokenPending: boolean;
  createTokenSuccess: boolean;
  createTokenError: string | null;

  isDisableTokenPending: boolean;
  disableTokenSuccess: boolean;
  disableTokenError: string | null;

  otpInfo: OtpInfo | null;
  isFetchOtpInfoPending: boolean;
  otpInfoFetchError: string | null;

  isGenerateOtpPending: boolean;
  generateOtpSuccess: boolean;
  generateOtpError: string | null;

  selectedOrgId: string | undefined;
  shouldRedirectToMainPage: boolean;
}

const initialState: StateSlice = {
  profile: null,
  isFetchProfilePending: false,
  profileFetchError: null,
  profileFetchErrorStatus: null,

  isPatchProfilePending: false,
  profilePatchSuccess: false,
  profilePatchError: null,

  isPatchPasswordPending: false,
  passwordPatchSuccess: false,
  passwordPatchError: null,

  isCreateTokenPending: false,
  createTokenSuccess: false,
  createTokenError: null,

  isDisableTokenPending: false,
  disableTokenSuccess: false,
  disableTokenError: null,

  otpInfo: null,
  isFetchOtpInfoPending: false,
  otpInfoFetchError: null,

  isGenerateOtpPending: false,
  generateOtpSuccess: false,
  generateOtpError: null,

  selectedOrgId: undefined,
  shouldRedirectToMainPage: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    fetchProfile(_state) {
      //empty handled by saga
    },
    setProfilePending(state) {
      state.isFetchProfilePending = true;
    },
    setFetchProfileError(
      state,
      action: PayloadAction<{ error: string; status: number | null }>
    ) {
      state.isFetchProfilePending = false;
      state.profileFetchError = action.payload.error;
      state.profileFetchErrorStatus = action.payload.status;
    },
    fetchProfileSuccess(state, action: PayloadAction<Profile>) {
      state.isFetchProfilePending = false;
      state.profile = action.payload;
      state.profileFetchError = null;
      state.profileFetchErrorStatus = null;
    },

    patchProfile(_state, _action: PayloadAction<Partial<Profile>>) {
      //empty handled by saga
    },
    setPatchProfilePending(state) {
      state.isPatchProfilePending = true;
      state.profilePatchSuccess = false;
    },
    setPatchProfileError(state, action: PayloadAction<string>) {
      state.isPatchProfilePending = false;
      state.profilePatchError = action.payload;
      state.profilePatchSuccess = false;
    },
    setPatchProfileSuccess(state) {
      state.isPatchProfilePending = false;
      state.profilePatchError = null;
      state.profilePatchSuccess = true;
    },
    resetPatchProfileState(state) {
      state.isPatchProfilePending = false;
      state.profilePatchError = null;
      state.profilePatchSuccess = false;
    },

    patchPassword(_state, _action: PayloadAction<PasswordData>) {
      //empty handled by saga
    },
    setPatchPasswordPending(state) {
      state.isPatchPasswordPending = true;
      state.passwordPatchSuccess = false;
      state.passwordPatchError = null;
    },
    setPatchPasswordError(state, action: PayloadAction<string>) {
      state.isPatchPasswordPending = false;
      state.passwordPatchError = action.payload;
      state.passwordPatchSuccess = false;
    },
    setPatchPasswordSuccess(state) {
      state.isPatchPasswordPending = false;
      state.passwordPatchError = null;
      state.passwordPatchSuccess = true;
    },
    resetPatchPasswordState(state) {
      state.isPatchPasswordPending = false;
      state.passwordPatchError = null;
      state.passwordPatchSuccess = false;
    },

    createToken(_state) {
      //empty handled by saga
    },
    setIsCreateTokenPending(state) {
      state.isCreateTokenPending = true;
      state.createTokenSuccess = false;
    },
    setCreateTokenError(state, action: PayloadAction<string>) {
      state.isCreateTokenPending = false;
      state.createTokenError = action.payload;
      state.createTokenSuccess = false;
    },
    setCreateTokenSuccess(state) {
      state.isCreateTokenPending = false;
      state.createTokenError = null;
      state.createTokenSuccess = true;
    },
    resetCreateTokenState(state) {
      state.isCreateTokenPending = false;
      state.createTokenError = null;
      state.createTokenSuccess = false;
    },

    disableToken(_state) {
      //empty handled by saga
    },
    setIsDisableTokenPending(state) {
      state.isDisableTokenPending = true;
      state.disableTokenSuccess = false;
    },
    setDisableTokenError(state, action: PayloadAction<string>) {
      state.isDisableTokenPending = false;
      state.disableTokenError = action.payload;
      state.disableTokenSuccess = false;
    },
    setDisableTokenSuccess(state) {
      state.isDisableTokenPending = false;
      state.disableTokenError = null;
      state.disableTokenSuccess = true;
    },
    resetDisableTokenState(state) {
      state.isDisableTokenPending = false;
      state.disableTokenError = null;
      state.disableTokenSuccess = false;
    },

    fetchOtpInfo(_state) {
      //empty handled by saga
    },
    setFetchOtpInfoPending(state) {
      state.isFetchOtpInfoPending = true;
      state.otpInfo = null;
      state.otpInfoFetchError = null;
    },
    setFetchOtpInfoError(state, action: PayloadAction<string>) {
      state.isFetchOtpInfoPending = true;
      state.otpInfo = null;
      state.otpInfoFetchError = action.payload;
    },
    fetchOtpInfoSuccess(state, action: PayloadAction<OtpInfo>) {
      state.isFetchOtpInfoPending = false;
      state.otpInfo = action.payload;
      state.otpInfoFetchError = null;
    },
    resetFetchOtpInfoState(state) {
      state.isFetchOtpInfoPending = false;
      state.otpInfo = null;
      state.otpInfoFetchError = null;
    },

    generateOtpInfo(_state, _action: PayloadAction<OtpPostData>) {
      //empty handled by saga
    },
    setIsGenerateOtpPending(state) {
      state.isGenerateOtpPending = true;
      state.generateOtpError = null;
      state.generateOtpSuccess = false;
    },
    setGenerateOtpError(state, action: PayloadAction<string>) {
      state.isGenerateOtpPending = false;
      state.generateOtpError = action.payload;
      state.generateOtpSuccess = false;
    },
    setGenerateOtpSuccess(state) {
      state.isGenerateOtpPending = false;
      state.generateOtpError = null;
      state.generateOtpSuccess = true;
    },
    resetGenerateOtpState(state) {
      state.isGenerateOtpPending = false;
      state.generateOtpError = null;
      state.generateOtpSuccess = false;
    },
    setSelectedOrgId(
      state,
      action: PayloadAction<{
        organizationId: string;
        shouldRedirectToMainPage?: boolean;
      }>
    ) {
      state.selectedOrgId = action.payload.organizationId;
      state.shouldRedirectToMainPage =
        action.payload.shouldRedirectToMainPage ?? true;

      axiosCsisApi.updateHeader(
        "Organization-ID",
        action.payload.organizationId
      );
    },
  },
});

export default profileSlice.reducer;

export const {
  fetchProfile,
  setProfilePending,
  setFetchProfileError,
  fetchProfileSuccess,

  patchProfile,
  setPatchProfilePending,
  setPatchProfileError,
  setPatchProfileSuccess,
  resetPatchProfileState,

  createToken,
  setIsCreateTokenPending,
  setCreateTokenError,
  setCreateTokenSuccess,
  resetCreateTokenState,

  disableToken,
  setIsDisableTokenPending,
  setDisableTokenError,
  setDisableTokenSuccess,
  resetDisableTokenState,

  patchPassword,
  setPatchPasswordPending,
  setPatchPasswordError,
  setPatchPasswordSuccess,
  resetPatchPasswordState,

  fetchOtpInfo,
  setFetchOtpInfoPending,
  setFetchOtpInfoError,
  fetchOtpInfoSuccess,
  resetFetchOtpInfoState,

  generateOtpInfo,
  setIsGenerateOtpPending,
  setGenerateOtpError,
  setGenerateOtpSuccess,
  resetGenerateOtpState,

  setSelectedOrgId,
} = profileSlice.actions;

// Async stuff - sagas

function* fetchProfileSaga() {
  yield put(setProfilePending());
  try {
    const response: AxiosResponse<ProfileResponse> = yield call(
      fetchProfileApi
    );

    const currentOrganizationId: string | undefined = yield select(
      getSelectedOrgId
    );

    if (
      isNullOrUndefined(currentOrganizationId) &&
      response.data.payload.legacy_organizations?.length > 0
    ) {
      const orgId = response.data.payload.legacy_organizations[0]?.external_id;
      if (orgId) {
        yield put(
          setSelectedOrgId({
            organizationId: orgId,
            shouldRedirectToMainPage: false,
          })
        );
      }
    }
    yield put(fetchProfileSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);

    yield put(
      // @ts-expect-error
      setFetchProfileError({ error: errorMessage, status: e?.response?.status })
    );
  }
}

function* patchProfileSaga(action: PayloadAction<Partial<Profile>>) {
  yield put(setPatchProfilePending());

  try {
    yield call(patchProfileApi, action.payload);

    yield put(setPatchProfileSuccess());
    // refetch the profile after a successful update
    yield put(fetchProfile());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPatchProfileError(errorMessage));
  }
}

function* patchPasswordSaga(action: PayloadAction<PasswordData>) {
  yield put(setPatchPasswordPending());

  try {
    yield call(changePasswordApi, action.payload);

    yield put(setPatchPasswordSuccess());
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPatchPasswordError(errorMessage));
  }
}

function* createTokenSaga() {
  yield put(setIsCreateTokenPending());

  try {
    yield call(createTokenApi);

    yield put(setCreateTokenSuccess());
    // refetch the profile after a successful update
    yield put(fetchProfile());
  } catch (e) {
    yield put(setCreateTokenError("Something went wrong"));
  }
}

function* disableTokenSaga() {
  yield put(setIsDisableTokenPending());

  try {
    yield call(disableTokenApi);

    yield put(fetchProfile());
    yield put(setDisableTokenSuccess());
  } catch (e) {
    yield put(setDisableTokenError("Something went wrong"));
  }
}

function* fetchOtpInfoSaga() {
  yield put(setFetchOtpInfoPending());
  try {
    const response: AxiosResponse<OtpInfoResponse> = yield call(
      fetchOtpInfoApi
    );

    yield put(fetchOtpInfoSuccess(response.data.payload));
  } catch (e) {
    const errorMessage = handleRequestError(e);
    yield put(setPatchPasswordError(errorMessage));
  }
}

function* generateOtpInfoSaga(action: PayloadAction<OtpPostData>) {
  yield put(setIsGenerateOtpPending());

  try {
    yield call(generateOtpApi, action.payload);

    yield put(setGenerateOtpSuccess());
    // refetch the profile after a successful update
    yield put(fetchProfile());
  } catch (e) {
    yield put(setGenerateOtpError("Something went wrong"));
  }
}

function* actionWatcher() {
  yield takeLatest(fetchProfile.toString(), fetchProfileSaga);
  yield takeLatest(patchProfile.toString(), patchProfileSaga);
  yield takeLatest(patchPassword.toString(), patchPasswordSaga);
  yield takeLatest(createToken.toString(), createTokenSaga);
  yield takeLatest(disableToken.toString(), disableTokenSaga);
  yield takeLatest(fetchOtpInfo.toString(), fetchOtpInfoSaga);
  yield takeLatest(generateOtpInfo.toString(), generateOtpInfoSaga);
}

export function* profileSagas() {
  yield all([actionWatcher()]);
}
