import urlTemplates from "./urlTemplates";

// all valid platform Urls should be defined here
// use camel_case for slashes
const urls = {
  resetpassword: "/reset-password",
  resetpassword_email: "/reset-password-email",
  // **
  // ** Products **
  products: "/products",
  // ** Global Search **
  globalsearch: "/global-search",
  // ** Emergency Response Retainer ** //
  products_emergencyresponse: "/products/emergency-response",
  products_emergencyresponse_retainer: "/products/emergency-response/retainer",
  // ** Threat Insights **
  products_threatinsights: "/products/threat-insights",
  products_threatinsights_article: "/products/threat-insights/article",
  products_threatinsights_article_id:
    urlTemplates._products_threatinsights_article_id(":id"),
  // ** Phishing **
  products_phishing: "/products/anti-phishing",
  products_phishing_urls: "/products/anti-phishing/urls",
  products_phishing_urls_url: "/products/anti-phishing/urls/url",
  products_phishing_urls_url_id:
    urlTemplates._products_phishing_urls_url_id(":id"),
  products_phishing_statistics: "/products/anti-phishing/statistics",
  products_phishing_usage: "/products/anti-phishing/usage",
  // ** Remote forensics **
  products_remoteforensics: "/products/remote-forensics",
  products_remoteforensics_search: "/products/remote-forensics/search",
  products_remoteforensics_download: "/products/remote-forensics/download",
  products_remoteforensics_statistics: "/products/remote-forensics/statistics",
  products_remoteforensics_case: "/products/remote-forensics/case",
  products_remoteforensics_case_id:
    urlTemplates._products_remoteforensics_case_id(":id"),
  products_remoteforensics_case_id_analysisoverview:
    urlTemplates._products_remoteforensics_case_id_analysisoverview(":id"),
  products_remoteforensics_case_id_detectiontools:
    urlTemplates._products_remoteforensics_case_id_detectiontools(":id"),
  products_remoteforensics_case_id_processes:
    urlTemplates._products_remoteforensics_case_id_processes(":id"),
  products_remoteforensics_case_id_memoryanalysis:
    urlTemplates._products_remoteforensics_case_id_memoryanalysis(":id"),
  products_remoteforensics_case_id_scheduledtasks:
    urlTemplates._products_remoteforensics_case_id_scheduledtasks(":id"),
  products_remoteforensics_case_id_filesystem:
    urlTemplates._products_remoteforensics_case_id_filesystem(":id"),
  products_remoteforensics_case_id_filesystem_suspiciousextensions:
    urlTemplates._products_remoteforensics_case_id_filesystem_suspiciousextensions(
      ":id"
    ),
  products_remoteforensics_case_id_filesystem_knownbadhashes:
    urlTemplates._products_remoteforensics_case_id_filesystem_knownbadhashes(
      ":id"
    ),
  products_remoteforensics_case_id_filesystem_possiblemalwaredroppers:
    urlTemplates._products_remoteforensics_case_id_filesystem_possiblemalwaredroppers(
      ":id"
    ),
  products_remoteforensics_case_id_filesystem_suspiciousfiles:
    urlTemplates._products_remoteforensics_case_id_filesystem_suspiciousfiles(
      ":id"
    ),
  products_remoteforensics_case_id_filesystem_remoteadministrationtools:
    urlTemplates._products_remoteforensics_case_id_filesystem_remoteadministrationtools(
      ":id"
    ),
  products_remoteforensics_case_id_filesystem_amcache:
    urlTemplates._products_remoteforensics_case_id_filesystem_amcache(":id"),
  products_remoteforensics_case_id_filesystem_shim:
    urlTemplates._products_remoteforensics_case_id_filesystem_shim(":id"),
  products_remoteforensics_case_id_network_dnsqueries:
    urlTemplates._products_remoteforensics_case_id_network_dnsqueries(":id"),
  products_remoteforensics_case_id_network_connections:
    urlTemplates._products_remoteforensics_case_id_network_connections(":id"),
  products_remoteforensics_case_id_network_openports:
    urlTemplates._products_remoteforensics_case_id_network_openports(":id"),
  products_remoteforensics_case_id_registryhives_autorunkeys:
    urlTemplates._products_remoteforensics_case_id_registryhives_autorunkeys(
      ":id"
    ),
  products_remoteforensics_case_id_registryhives_remoteadministrationtools:
    urlTemplates._products_remoteforensics_case_id_registryhives_remoteadministrationtools(
      ":id"
    ),
  products_remoteforensics_case_id_registryhives_suspiciousregistrykeys:
    urlTemplates._products_remoteforensics_case_id_registryhives_suspiciousregistrykeys(
      ":id"
    ),
  products_remoteforensics_case_id_browser_suspiciousvisits:
    urlTemplates._products_remoteforensics_case_id_browser_suspiciousvisits(
      ":id"
    ),
  products_remoteforensics_case_id_baselinecomparison_registry:
    urlTemplates._products_remoteforensics_case_id_baselinecomparison_registry(
      ":id"
    ),
  products_remoteforensics_case_id_applications:
    urlTemplates._products_remoteforensics_case_id_applications(":id"),
  products_remoteforensics_case_id_storage:
    urlTemplates._products_remoteforensics_case_id_storage(":id"),
  // ** Compromised data **
  products_compromiseddata: "/products/compromised-data",
  products_compromiseddata_cc:
    "/products/compromised-data/payment-cards-reports",
  products_compromiseddata_cc_search:
    "/products/compromised-data/payment-cards-reports/search",
  products_compromiseddata_cc_statistics:
    "/products/compromised-data/payment-cards-reports/statistics",
  products_compromiseddata_cc_report:
    "/products/compromised-data/payment-cards-reports/report",
  products_compromiseddata_cc_report_id:
    urlTemplates._products_compromiseddata_cc_report_id(":id"),

  products_compromiseddata_victims:
    "/products/compromised-data/credential-reports",
  products_compromiseddata_victims_search:
    "/products/compromised-data/credential-reports/search",
  products_compromiseddata_victims_statistics:
    "/products/compromised-data/credential-reports/statistics",
  products_compromiseddata_victims_report:
    "/products/compromised-data/credential-reports/report",
  products_compromiseddata_victims_report_id:
    urlTemplates._products_compromiseddata_victims_report_id(":id"),

  // **
  // ** Investigations **
  products_investigations: "/products/investigations",
  products_investigations_search: "/products/investigations/search",
  products_investigations_threatcloud: "/products/investigations/threat-cloud",
  products_investigations_statistics: "/products/investigations/statistics",

  // **
  // ** Alerts **
  alerts: "/alerts",
  alerts_statistics: "/alerts/statistics",
  alerts_alert: "/alerts/alert",
  alerts_alert_id: urlTemplates._alerts_alert_id(":id"),
  alerts_search: "/alerts/search",
  // **
  // ** Tickets **
  tickets: "/tickets",
  tickets_search: "/tickets/search",
  tickets_ticket: "/tickets/ticket",
  tickets_ticket_id: urlTemplates._tickets_ticket_id(":id"),
  tickets_statistics: "/tickets/statistics",
  // **
  // ** Reports **
  reports: "/reports",

  admin: "/admin",
  // **
  // ** Retainers **
  admin_retainers: "/admin/retainers",
  admin_retainers_search: "/admin/retainers/search",
  admin_retainers_retainer_id: urlTemplates._admin_retainers_retainer_id(":id"),
  // ** Emergency Case Confirmation **
  emergency_case_confirmation_id:
    urlTemplates._emergency_case_confirmation_id(":id"),
  // emergency_case_confirmation: "/emergency-response/case",
  // **
  // ** Emergency Cases **
  admin_emergency_cases: "/admin/emergency-cases",
  admin_emergency_cases_search: "/admin/emergency-cases/search",
  admin_emergency_cases_case_id: urlTemplates._admin_emergency_case_id(":id"),
  // **
  // ** Help support **
  helpandsupport: "/help-and-support",
  helpandsupport_helpcenter: "/help-and-support/help-center",
  helpandsupport_portalmanual: "/help-and-support/portal-manual",
  helpandsupport_apidocumentation: "/help-and-support/api-documentation",
  helpandsupport_mdr: "/help-and-support/mdr",

  // ** Settings **
  settings_account: "/settings/account",
  settings_account_general: "/settings/account/general",
  settings_account_notifications: "/settings/account/notifications",

  organization: "/organization",
  organization_apiclients: "/organization/api-clients",
  organization_auditlog: "/organization/audit-log",
  organization_usersaccess: "/organization/user-access",
  organization_customers_search: "/organization/customers/search",
  organization_customers_dashboard: "/organization/customers/dashboard",
  organization_customerinfo: "/organization/customer-info",

  organization_customerinfo_services: "/organization/customer-info/services",
  organization_customerinfo_health: "/organization/customer-info/health",

  logout: "/logout",

  // **
  // ** MDR **
  // Onboarding
  onboarding_mdfecustomer: "/onboarding/mdfe-customer",
  onboarding_mdfecustomer_id: urlTemplates._onboarding_mdfecustomer_id(":id"),

  // **
  // ** Tiger team **
  // Onboarding Questionnaire
  onboarding_tigerteam: "/onboarding/tt",
} as const;

// extract the urls as a union type so it returns "/products" | "/products/efp" ...
export type AllowedTipUrl = typeof urls[keyof typeof urls];

export default urls;
