export const removeTitleKey = (key: string, value: any) => {
  if (key === "title") {
    return undefined;
  }
  return value;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function getSectionId(sectionName?: string): string {
  return (
    sectionName?.toLowerCase().replace(/ /g, "-") || "missing-section-name"
  );
}
