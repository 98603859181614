import { OpenAPIV3 } from "openapi-types";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  CollapsablePanel,
  CollapsablePanelContent,
  CollapsablePanelSidebar,
  Stack,
} from "@csis.com/components";
import Page from "@csis.com/tip/src/components/shared/Page/Page";
import { profileKeys } from "@csis.com/tip/src/pages/Profile/Security/constants";
import { getProfile } from "@csis.com/tip/src/pages/Profile/Security/selectors";
import { Section } from "./Section/Section";
import { HelpSideBar } from "./Sidebar/HelpSideBar";
import { ecrimeApiUrl } from "./constants";
import { AlertsSubject } from "./content/Ecrime/AlertsSubject";
import { CompDataSubject } from "./content/Ecrime/CompDataSubject";
import { PhishingSubject } from "./content/Ecrime/PhishingSubject";
import { TicketsSubject } from "./content/Ecrime/TicketsSubject";
import { TokenAuthenticationSubject } from "./content/Ecrime/TokenAuthenticationSubject";
import * as portalApiDereferenced from "./content/Portals/mockOpenApiDereferenced.json";
import { parseOpenApiSpec } from "./content/utils/parser";

export const ApiDocumentation: FC = () => {
  const history = useHistory();
  const isAdmin = useSelector(getProfile)?.[profileKeys.IS_SUPPOSEDLY_ADMIN];

  const portalSection = parseOpenApiSpec(
    portalApiDereferenced as OpenAPIV3.Document,
    "Portals API"
  );

  const generatedSections = [portalSection];

  const hashLink = history.location.hash;
  useEffect(() => {
    if (hashLink) {
      document.getElementById(hashLink.replace("#", ""))?.scrollIntoView();
    }
  }, [hashLink]);

  return (
    <Page classes="apidocumentation" padding="none">
      <CollapsablePanel>
        <CollapsablePanelSidebar isCollapsed={false} isSticky>
          <HelpSideBar sections={generatedSections} isAdmin={isAdmin} />
        </CollapsablePanelSidebar>
        <CollapsablePanelContent>
          <Stack isVertical gutterSize="gigantic">
            <h1 className="f_gigantic f_semibold f_csis">CSIS APIs</h1>

            <Section title="eCrime APIs" titleId="ecrime-apis">
              <p>
                eCrime HTTP APIs The external customer-facing portal has a
                public HTTP API for pulling ecrime data. This is useful for
                integrating with various Security Management/Ticket systems.
                Alerts and Tickets API endpoints are always available. The rest
                are subscription based. (E.g. you cannot access the Compromised
                Data API without first subscribing to our Compromised Data
                service)
              </p>
              <p>
                The API currently resides on
                <span className="f_big f_csis">{` ${ecrimeApiUrl}`}</span>
              </p>
              <TokenAuthenticationSubject />
              <AlertsSubject />
              <CompDataSubject />
              <TicketsSubject />
              <PhishingSubject />
            </Section>

            {isAdmin && portalSection && (
              <Section
                title="Portals API"
                titleId="portals-api"
                sectionData={portalSection}
              />
            )}
          </Stack>
        </CollapsablePanelContent>
      </CollapsablePanel>
    </Page>
  );
};
