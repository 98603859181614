export const API_ENDPOINTS = {
  //Remote Forensics
  remoteforensics: "/1.0/remote-forensics/",
  remoteforensics_id_subpage: (id: string, subpage?: string) =>
    subpage
      ? `/1.0/remote-forensics/${id}/${subpage}`
      : `/1.0/remote-forensics/${id}`,
} as const;

export type AllowedEndpoint = typeof API_ENDPOINTS[keyof typeof API_ENDPOINTS];
