import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSelectedOrgId } from "@csis.com/tip/src/pages/Profile/Security/selectors";
import { navigateOrOpenNewTab } from "@csis.com/tip/src/utils/navigateOrOpenNewTab";

const useNavigateOnRowClick = (
  idKey: string,
  urlTemplate: (id: string) => string
) => {
  const history = useHistory();
  const selectedOrgId = useSelector(getSelectedOrgId);

  const handleRowClick = useCallback(
    (row, isCtrlKeyPressed) => {
      const rowId = row[idKey];
      if (rowId) {
        navigateOrOpenNewTab(
          urlTemplate(rowId),
          history,
          isCtrlKeyPressed,
          selectedOrgId
        );
      }
    },
    [history, idKey, selectedOrgId, urlTemplate]
  );

  return { handleRowClick };
};

export default useNavigateOnRowClick;
