type Id = string;

const urlTemplates = {
  _products_phishing_urls_url_id: (id: Id) =>
    `/products/anti-phishing/urls/url/${id}`,
  _products_remoteforensics_case_id: (id: Id) =>
    `/products/remote-forensics/case/${id}`,
  _products_remoteforensics_case_id_analysisoverview: (id: Id) =>
    `/products/remote-forensics/case/${id}/analysis-overview`,
  _products_remoteforensics_case_id_detectiontools: (id: Id) =>
    `/products/remote-forensics/case/${id}/detection-tools`,
  _products_remoteforensics_case_id_processes: (id: Id) =>
    `/products/remote-forensics/case/${id}/processes`,
  _products_remoteforensics_case_id_memoryanalysis: (id: Id) =>
    `/products/remote-forensics/case/${id}/memory-analysis`,
  _products_remoteforensics_case_id_scheduledtasks: (id: Id) =>
    `/products/remote-forensics/case/${id}/scheduled-tasks`,
  _products_remoteforensics_case_id_filesystem: (id: Id) =>
    `/products/remote-forensics/case/${id}/file-system`,
  _products_remoteforensics_case_id_filesystem_suspiciousextensions: (id: Id) =>
    `/products/remote-forensics/case/${id}/file-system/suspicious-extension`,
  _products_remoteforensics_case_id_filesystem_knownbadhashes: (id: Id) =>
    `/products/remote-forensics/case/${id}/file-system/known-bad-hashes`,
  _products_remoteforensics_case_id_filesystem_possiblemalwaredroppers: (
    id: Id
  ) =>
    `/products/remote-forensics/case/${id}/file-system/possible-malware-droppers`,
  _products_remoteforensics_case_id_filesystem_suspiciousfiles: (id: Id) =>
    `/products/remote-forensics/case/${id}/file-system/suspicious-files`,
  _products_remoteforensics_case_id_filesystem_remoteadministrationtools: (
    id: Id
  ) =>
    `/products/remote-forensics/case/${id}/file-system/remote-administration-tools`,
  _products_remoteforensics_case_id_filesystem_amcache: (id: Id) =>
    `/products/remote-forensics/case/${id}/file-system/amcache`,
  _products_remoteforensics_case_id_filesystem_shim: (id: Id) =>
    `/products/remote-forensics/case/${id}/file-system/shim`,
  _products_remoteforensics_case_id_network_dnsqueries: (id: Id) =>
    `/products/remote-forensics/case/${id}/network/dns-queries`,
  _products_remoteforensics_case_id_network_connections: (id: Id) =>
    `/products/remote-forensics/case/${id}/network/connections`,
  _products_remoteforensics_case_id_network_openports: (id: Id) =>
    `/products/remote-forensics/case/${id}/network/open-ports`,
  _products_remoteforensics_case_id_registryhives_autorunkeys: (id: Id) =>
    `/products/remote-forensics/case/${id}/registry-hives/autorun-keys`,
  _products_remoteforensics_case_id_registryhives_remoteadministrationtools: (
    id: Id
  ) =>
    `/products/remote-forensics/case/${id}/registry-hives/remote-administration-tools`,
  _products_remoteforensics_case_id_registryhives_suspiciousregistrykeys: (
    id: Id
  ) =>
    `/products/remote-forensics/case/${id}/registry-hives/suspicious-registry-keys`,
  _products_remoteforensics_case_id_browser_suspiciousvisits: (id: Id) =>
    `/products/remote-forensics/case/${id}/browser/suspicious-visits`,
  _products_remoteforensics_case_id_baselinecomparison_registry: (id: Id) =>
    `/products/remote-forensics/case/${id}/baseline-comparison/registry`,
  _products_remoteforensics_case_id_applications: (id: Id) =>
    `/products/remote-forensics/case/${id}/applications`,
  _products_remoteforensics_case_id_storage: (id: Id) =>
    `/products/remote-forensics/case/${id}/external-storage`,

  _alerts_alert_id: (id: Id) => `/alerts/alert/${id}`,
  _tickets_ticket_id: (id: Id) => `/tickets/ticket/${id}`,
  _admin_retainers_retainer_id: (id: Id) => `/admin/retainers/retainer/${id}`,
  _admin_emergency_case_id: (id: Id) => `/admin/emergency/case/${id}`,
  _products_compromiseddata_cc_report_id: (id: Id) =>
    `/products/compromised-data/payment-cards-reports/report/${id}`,
  _products_compromiseddata_victims_report_id: (id: Id) =>
    `/products/compromised-data/credential-reports/report/${id}`,
  _products_threatinsights_article_id: (id: Id) =>
    `/products/threat-insights/article/${id}`,
  // EC confirmation
  _emergency_case_confirmation_id: (id: Id) => `/emergency-response/case/${id}`,

  // MDR
  _onboarding_mdfecustomer_id: (id: Id) => `/onboarding/mdfe-customer/${id}`,
} as const;

export default urlTemplates;
