import { FC } from "react";
import { CodeBlock, Divider } from "@csis.com/components";
import { DocumentationCodeExample } from "../../DocumentationCodeExample/DocumentationCodeExample";
import {
  EndpointSection,
  EndpointSectionContent,
  EndpointSectionHeader,
} from "../../EndpointSection/EndpointSection";
import {
  QueryParamList,
  QueryParamListTerm,
  QueryParamListTermDetails,
} from "../../QueryParamList/QueryParamList";
import { Subject } from "../../Subject/Subject";
import { ENDPOINT_HEADING_LEVELS } from "../../Subject/constants";
import { ecrimeApiUrl } from "../../constants";

export const AlertsSubject: FC = () => {
  return (
    <Subject title="Alerts" id="alerts">
      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}alerts/subscribed-alerters`}
          method="GET"
          title="List subscribed Alerters"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="list-subscribed-alerters"
        />
        <EndpointSectionContent>
          <p>
            List information about all alerters the current user has access to.
          </p>

          <ListSubscribedAlertersExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}alerts/<Alert ID>`}
          method="GET"
          title="Alert Detail"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="alert-detail"
        />
        <EndpointSectionContent>
          <p>Fetches a single alert with the given ID.</p>

          <AlertDetailExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}alerts/`}
          method="GET"
          title="Alert Search"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="alert-search"
        />
        <EndpointSectionContent>
          <QueryParamList>
            <QueryParamListTerm term="a.id-eq" types={["Integer"]} isOptional />
            <QueryParamListTermDetails>
              <p>ID of the released alert.</p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="a.alerter-eq"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                ID of the alerter. The alerter IDs can be accessed
                programmatically through the "List subscribed Alerters" endpoint
                described above.
              </p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="a.severity-eq"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <ol type="1" start={1}>
                <li>N/A</li>
                <li>Info</li>
                <li>Low</li>
                <li>Medium</li>
                <li>High</li>
                <li>Critical</li>
                <li>False Positive</li>
              </ol>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="a.status-eq"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <ol type="1" start={1}>
                <li>New</li>
                <li>In Progress</li>
                <li>Closed</li>
                <li>Pending</li>
                <li>Confirmed</li>
              </ol>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="released-after"
              types={["String", "Date", "Datetime"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>Date (yyyy-mm-dd) or Datetime (yyyy-mm-dd hh:mm:ss).</p>
            </QueryParamListTermDetails>

            <QueryParamListTerm
              term="released-before"
              types={["String", "Date", "Datetime"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>Date (yyyy-mm-dd) or Datetime (yyyy-mm-dd hh:mm:ss).</p>
            </QueryParamListTermDetails>
          </QueryParamList>

          <AlertsSearchExample />
        </EndpointSectionContent>
      </EndpointSection>
    </Subject>
  );
};

const ListSubscribedAlertersExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}alerts/subscribed-alerters`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}alerts/subscribed-alerters'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json() `}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "objects": [
    {
      "display_name": "Blacklisting monitor",
      "id": 1,
      "name": "mailserver_blacklist"
    },
    {
      "display_name":"Typosquatting monitor",
      "id":2,
      "name":"typosquatting",
    }
  ]
}`}
      />
    </DocumentationCodeExample>
  );
};

const AlertDetailExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}alerts/123456789`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}alerts/123456789'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json() `}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
    "alerter": "name_of_alerter",
    "id": 123456789,
    "owner": "Business Unit",
    "released": "YYYY-MM-DDTHH:MM:SS",
    "severity": "High",
    "status": "New",
    "text": "Alert content\\nAlert content continued after linebreak",
    "title": "Title of the alert"
}`}
      />
    </DocumentationCodeExample>
  );
};

const AlertsSearchExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <a
        href={`${ecrimeApiUrl}/static/manual/search_alerts.txt`}
        className="f_csis"
      >
        Sample Alerts API usage
      </a>

      <h5 className="f_semibold">curl</h5>
      <p>Get all alerts categorised as high or critical:</p>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}alerts/?a.severity-eq=5&a.severity-eq=6`}
      />

      <h5 className="f_semibold">curl</h5>
      <p>Get all alerts released after May 1st, 2012:</p>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}alerts/?released-after=2012-06-01`}
      />

      <h5 className="f_semibold">Python</h5>
      <p>Get all alerts categorised as high or critical:</p>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}alerts'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
params = {'a.severity-eq': '5', 'a.severity-eq': '6'}
r = requests.get(url, headers=headers, params=params)
r.raise_for_status()
r.json()`}
      />

      <h5 className="f_semibold">Python</h5>
      <p>Get all alerts released after May 1st, 2012:</p>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}alerts'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
params = {'released-after': '2012-06-01'}
r = requests.get(url, headers=headers, params=params)
r.raise_for_status()
r.json()`}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
"objects": [
  {
    "alerter": "typosquatting",
    "display_name": "Typosquatting monitor",
    "id": 9999999,
    "name": "Info",
    "owner": "Example Business Unit",
    "released": "YYYY-MM-DD HH:mm:ss",
    "severity": "Info",
    "title": "Typo domain: exaample.com"
    "text": "Domain Name:\n\texaample.com\n\nTarget Domain Name:\n\texample.com..."
  },
  {...}
]
}`}
      />
    </DocumentationCodeExample>
  );
};
