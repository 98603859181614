/* eslint-disable sonarjs/no-duplicate-string */
import { OpenAPIV3 } from "openapi-types";
import { FC, Fragment } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
  CodeBlock,
  Divider,
  Stack,
} from "@csis.com/components";
import {
  EndpointSection,
  EndpointSectionContent,
  EndpointSectionHeader,
} from "../EndpointSection/EndpointSection";
import {
  ParamList,
  ParamListTerm,
  ParamListTermDetails,
} from "../ParamList/ParamList";
import { Subject } from "../Subject/Subject";
import { removeTitleKey } from "../content/utils/utils";
import {
  DocumentationSection,
  isReferenceObject,
  isSchemaObject,
} from "./types";

interface SectionInterface {
  title: string;
  titleId: string;
  sectionData?: DocumentationSection;
}

export const Section: FC<SectionInterface> = ({
  title,
  titleId,
  children,
  sectionData,
}) => {
  return (
    <Stack isVertical gutterSize="big" align="stretch">
      <h2 id={titleId} className="f_huge f_semibold f_csis">
        {title}
      </h2>
      {sectionData ? (
        <GeneratedSubjects titleId={titleId} sectionData={sectionData} />
      ) : (
        children
      )}
    </Stack>
  );
};

const GeneratedSubjects: FC<{
  titleId: string;
  sectionData: DocumentationSection;
}> = ({ titleId, sectionData }) => {
  if (!sectionData) return <p>no data</p>;

  const uppercaseTitle = (tag: string) => {
    return tag
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(", ");
  };

  return (
    <>
      {sectionData &&
        sectionData.paths &&
        Array.from(sectionData.tags).map((tag) => (
          <Subject
            id={`${titleId}-${tag}`}
            title={uppercaseTitle(tag)}
            key={tag}
          >
            <EndpointSection>
              {sectionData.paths.map((path) => (
                <Fragment key={path.endpoint}>
                  {path.operations.map((operation) => {
                    if (operation.tags?.join("-") !== tag) return null;
                    return (
                      <Fragment key={operation.operationId}>
                        <EndpointSectionHeader
                          endpoint={path.endpoint}
                          method={operation.method}
                          title={operation.summary}
                          titleId={operation.operationId}
                        />
                        <EndpointSectionContent>
                          <p>
                            {operation.description ||
                              "No description for this endpoint"}
                          </p>

                          {operation.parameters && (
                            <GeneratedParamList
                              operationParameters={operation.parameters}
                              apiComponents={sectionData.components}
                            />
                          )}

                          {operation.requestBody && (
                            <RequestBodyExample
                              requestBody={operation.requestBody}
                            />
                          )}

                          {operation.responses && (
                            <PortalsAPICodeExample
                              responses={operation.responses}
                            />
                          )}
                        </EndpointSectionContent>
                        <Divider />
                      </Fragment>
                    );
                  })}
                </Fragment>
              ))}
            </EndpointSection>
          </Subject>
        ))}
    </>
  );
};

const GeneratedParamList: FC<{
  operationParameters?: (
    | OpenAPIV3.ReferenceObject
    | OpenAPIV3.ParameterObject
  )[];
  apiComponents?: OpenAPIV3.ComponentsObject;
}> = ({ operationParameters }) => {
  if (!operationParameters) return <p>No parameters for this operation</p>;

  const paramListItem = (parameterObject: OpenAPIV3.ParameterObject) => {
    const schemaObject =
      parameterObject.schema as OpenAPIV3.SchemaObject | null;

    if (!schemaObject) return null;

    const parameterSchemaType = isSchemaObject(schemaObject)
      ? schemaObject.type
      : undefined;

    return (
      <>
        <ParamListTerm
          term={parameterObject.name}
          isOptional={!parameterObject.required}
          types={parameterSchemaType ? [parameterSchemaType] : undefined}
          paramLocation={parameterObject.in}
        />
        <ParamListTermDetails>
          <p>
            {parameterObject.description
              ? parameterObject.description
              : "No description for this parameter"}
          </p>
        </ParamListTermDetails>
      </>
    );
  };

  return (
    <ParamList>
      {operationParameters?.map((parameter, index) => {
        return (
          <Fragment key={index}>
            {paramListItem(parameter as OpenAPIV3.ParameterObject)}
          </Fragment>
        );
      })}
    </ParamList>
  );
};

// The following components are for the response code examples
const PortalsAPICodeExample: FC<{
  responses: OpenAPIV3.ResponsesObject | undefined;
}> = ({ responses }) => {
  return (
    <Accordion headerGutterSize="space-between" headerColor="grey">
      <AccordionItem>
        <AccordionItemHeader>
          <span className="f_normal">Response examples</span>
        </AccordionItemHeader>
        <AccordionItemBody>
          <Stack isVertical align="stretch">
            {responses &&
              Object.entries(responses).map(([key, response]) => {
                if (isReferenceObject(response)) {
                  return null;
                }
                return (
                  <ResponseCodeObject
                    key={key}
                    response={response}
                    code={key}
                  />
                );
              })}
          </Stack>
        </AccordionItemBody>
      </AccordionItem>
    </Accordion>
  );
};

const ResponseCodeObject: FC<{
  response: OpenAPIV3.ResponseObject;
  code: String;
}> = ({ response, code }) => {
  return (
    <AccordionItem>
      <AccordionItemHeader>
        <span className="f_normal">
          Status code: {code} "{response.description}"
        </span>
      </AccordionItemHeader>
      <AccordionItemBody>
        <Stack isVertical align="stretch">
          {response.content ? (
            <ResponseContent content={response.content} />
          ) : (
            <span>No response content available.</span>
          )}
        </Stack>
      </AccordionItemBody>
    </AccordionItem>
  );
};

const ResponseContent: FC<{
  content: OpenAPIV3.MediaTypeObject;
}> = ({ content }) => {
  // Access the schema object within content
  const jsonContent = (content as Record<string, OpenAPIV3.MediaTypeObject>)[
    "application/json"
  ];

  const multipartContent = (
    content as Record<string, OpenAPIV3.MediaTypeObject>
  )["multipart/form-data"];

  const schema = jsonContent?.schema || multipartContent?.schema;

  const schemaProperties = isSchemaObject(schema)
    ? schema.properties
    : undefined;

  if (!schemaProperties) {
    return <span>There isn't any response content.</span>;
  }

  if (
    isSchemaObject(schema) &&
    schema.properties &&
    schema.properties["success"] &&
    isSchemaObject(schema.properties["success"])
  ) {
    delete schema.properties["success"].type;
  }

  const contentStringified = JSON.stringify(
    schemaProperties,
    removeTitleKey,
    3
  );

  return (
    <Stack isVertical align="stretch">
      <span className="f_normal">
        <span className="f_bold"> Media Type: </span>
        {jsonContent
          ? " application/json"
          : multipartContent
          ? " multipart/form-data"
          : ""}
      </span>
      {content && (
        <CodeBlock color="grey" isCopyable text={contentStringified} />
      )}
    </Stack>
  );
};

// The following components are for the RequestBodyObject
const RequestBodyExample: FC<{
  requestBody: OpenAPIV3.RequestBodyObject | undefined;
}> = ({ requestBody }) => {
  return (
    <Accordion headerGutterSize="space-between" headerColor="grey">
      <AccordionItem>
        <AccordionItemHeader>
          <span className="f_normal">Request Body</span>
        </AccordionItemHeader>
        <AccordionItemBody>
          <Stack isVertical align="stretch">
            <RequestBodyObject requestBody={requestBody} />
          </Stack>
        </AccordionItemBody>
      </AccordionItem>
    </Accordion>
  );
};

const RequestBodyObject: FC<{
  requestBody: OpenAPIV3.RequestBodyObject | undefined;
}> = ({ requestBody }) => {
  const jsonRequestBody = requestBody?.content?.["application/json"]?.schema;
  const multipartRequestBody =
    requestBody?.content?.["multipart/form-data"]?.schema;

  const requestBodyContent = jsonRequestBody || multipartRequestBody;

  const requestBodyProperties = isSchemaObject(requestBodyContent)
    ? requestBodyContent.properties
    : undefined;

  const codeBlockRequest = JSON.stringify(
    requestBodyProperties,
    removeTitleKey,
    3
  );

  return (
    <Stack isVertical align="stretch">
      <span className="f_normal">
        <span className="f_bold"> Media Type: </span>
        {jsonRequestBody
          ? " application/json"
          : multipartRequestBody
          ? " multipart/form-data"
          : ""}
      </span>
      <CodeBlock color="grey" isCopyable text={codeBlockRequest} />
    </Stack>
  );
};
