import { OpenAPIV3 } from "openapi-types";

export type DocumentationSection = {
  sectionName?: string;
  tags: Set<string>;
  paths: {
    endpoint: string;
    operations: EndpointOperation[];
  }[];
  components: OpenAPIV3.ComponentsObject | undefined;
};

export type EndpointOperation = {
  method: HttpMethod;
  summary: string | undefined;
  description: string | undefined;
  tags: string[] | undefined;
  parameters: OpenAPIV3.ParameterObject[] | undefined;
  operationId: string | undefined;
  responses?: OpenAPIV3.ResponsesObject | undefined;
  requestBody?: OpenAPIV3.RequestBodyObject | undefined;
};

export type HttpMethod = `${Uppercase<OpenAPIV3.HttpMethods>}` | undefined;

export type ApiComponents =
  | OpenAPIV3.SchemaObject
  | OpenAPIV3.ResponseObject
  | OpenAPIV3.ParameterObject
  | OpenAPIV3.ExampleObject
  | OpenAPIV3.RequestBodyObject
  | OpenAPIV3.HeaderObject
  | OpenAPIV3.SecuritySchemeObject
  | OpenAPIV3.LinkObject
  | OpenAPIV3.CallbackObject;

export function isReferenceObject(x: any): x is OpenAPIV3.ReferenceObject {
  return x.$ref !== undefined;
}

export function isSchemaObject(obj: any): obj is OpenAPIV3.SchemaObject {
  return (
    obj &&
    typeof obj === "object" &&
    "type" in obj &&
    typeof obj.type === "string"
  );
}
