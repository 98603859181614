import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { StatisticsHistogramCountListResponse } from "@csis.com/tip/src/api/openapi/data-contracts";
import { getSelectedOrgId } from "@csis.com/tip/src/pages/Profile/Security/selectors";
import { createAsyncArrayFetchSlice } from "@csis.com/tip/src/sliceHelpers/createAsyncArrayFetchSlice";
import { MatcherHistogramEntry, QueryParams } from "../types";
import { fetchMatcherStatisticsHistogramApi } from "./api/api";

const credentialsStatisticsSlice = createAsyncArrayFetchSlice<
  MatcherHistogramEntry,
  QueryParams
>("credentialsStatisticsMatcher");

export default credentialsStatisticsSlice.reducer;

export const {
  fetchData: fetchMatcherDistributionHistogram,
  setIsPending,
  setFetchError,
  setFetchSuccess,
} = credentialsStatisticsSlice.actions;

// Async stuff - sagas

function* fetchMatcherDistributionHistogramSaga(
  action: PayloadAction<Partial<QueryParams>>
) {
  yield put(setIsPending());

  try {
    const selectedOrgId: string = yield select(getSelectedOrgId);
    const response: AxiosResponse<StatisticsHistogramCountListResponse> =
      yield call(
        fetchMatcherStatisticsHistogramApi,
        action.payload,
        selectedOrgId
      );

    yield put(setFetchSuccess(response.data.payload));
  } catch (e) {
    yield put(setFetchError());
  }
}

function* actionWatcher() {
  yield takeLatest(
    fetchMatcherDistributionHistogram.toString(),
    fetchMatcherDistributionHistogramSaga
  );
}

export function* credentialsStatisticsMatcherSagas() {
  yield all([actionWatcher()]);
}
