import { FC } from "react";
import { CodeBlock, Divider } from "@csis.com/components";
import { DocumentationCodeExample } from "../../DocumentationCodeExample/DocumentationCodeExample";
import {
  EndpointSection,
  EndpointSectionContent,
  EndpointSectionHeader,
} from "../../EndpointSection/EndpointSection";
import {
  QueryParamList,
  QueryParamListTerm,
  QueryParamListTermDetails,
} from "../../QueryParamList/QueryParamList";
import { Subject } from "../../Subject/Subject";
import { ENDPOINT_HEADING_LEVELS } from "../../Subject/constants";
import { ecrimeApiUrl } from "../../constants";

export const PhishingSubject: FC = () => {
  return (
    <Subject title="Phishing" id="phishing">
      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}phishing/url?url=<url>`}
          method="GET"
          title="Check if a url is in our records"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="check-if-a-url-is-in-our-records"
        />
        <EndpointSectionContent>
          <p>
            Check if the given url is in our phishing records, and return
            several information about it. The URL is stripped of its
            query-string arguments before being submitted to the system.
          </p>
          <p>
            If there's no exact match, it checks if a similar URL exists in our
            system. If there is no match at all, the endpoint returns 404 and an
            empty JSON object.
          </p>

          <CheckUrlRecordsExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}phishing/domain?domain=<domain>`}
          method="GET"
          title="Check if a domain is in our records"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="check-if-a-domain-is-in-our-records"
        />
        <EndpointSectionContent>
          <p>
            Check if the given second-level domain name (not FQDN) is in our
            phishing records. The second level is, in the case of
            https://sub.example.com and example.com. If it is, it also returns
            (by default) the 5 most recent phishing URLs associated to it.{" "}
          </p>

          <QueryParamList>
            <QueryParamListTerm
              term="url_limit"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                If domain matches, return at most <em>url_limit</em> URLs.
                Limited to 100.
              </p>
            </QueryParamListTermDetails>
          </QueryParamList>

          <CheckDomainRecordsExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}phishing/fqdn?fqdn=<hostname>`}
          method="GET"
          title="Check if a hostname is in our records"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="check-if-a-hostname-is-in-our-records"
        />
        <EndpointSectionContent>
          <p>
            Check if the given hostname is in our phishing records. If it is, it
            also returns (by default) the 5 most recent phishing URLs associated
            to it.{" "}
          </p>

          <QueryParamList>
            <QueryParamListTerm
              term="url_limit"
              types={["Integer"]}
              isOptional
            />
            <QueryParamListTermDetails>
              <p>
                If hostname matches, return at most <em>url_limit</em> URLs.
                Limited to 100.
              </p>
            </QueryParamListTermDetails>
          </QueryParamList>

          <p>
            Check if hostname "www.example.hostname.com" is in our records, and
            if it is, returns at most the 25 most recent URLs associated to it.{" "}
          </p>

          <CheckHostnameRecordsExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}phishing/url/<URL Hash>`}
          method="GET"
          title="Get stats for a URL"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="get-stats-for-a-url"
        />
        <EndpointSectionContent>
          <p>
            Get various stats for a given URL: takedown stats if any, creation
            date, if it redirects to/from anywhere...{" "}
          </p>

          <UrlStatsExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}phishing/url/submit`}
          method="POST"
          title="Submit a URL"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="submit-a-url"
        />
        <EndpointSectionContent>
          <p>
            Submit a URL to the phishing system directly, instead of using the
            portal.
          </p>

          <SubmitUrlExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}phishing/urls`}
          method="GET"
          title="Get all URLs"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="get-all-urls"
        />
        <EndpointSectionContent>
          <p>Fetches a list of all relevant phishing URLs for your brand</p>

          <UrlsExample />
        </EndpointSectionContent>
      </EndpointSection>

      <Divider />

      <EndpointSection>
        <EndpointSectionHeader
          endpoint={`${ecrimeApiUrl}phishing/url/<URL Hash>/contents`}
          method="GET"
          title="Specific URL contents"
          headingLvl={ENDPOINT_HEADING_LEVELS.APIS}
          titleId="specific-url-contents"
        />
        <EndpointSectionContent>
          <QueryParamList>
            <QueryParamListTerm term="page" types={["Integer"]} />
            <QueryParamListTermDetails>
              <p>Page number you want to see.</p>
            </QueryParamListTermDetails>
          </QueryParamList>

          <SpecificUrlExample />
        </EndpointSectionContent>
      </EndpointSection>
    </Subject>
  );
};

const CheckUrlRecordsExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" "${ecrimeApiUrl}phishing/url?url=https://example.com"`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}phishing/url?url=https://example.com'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json() `}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "brand": "FACEBOOK",
  "created": "2019-07-01T21:28:46Z",
  "host": "faceb00k.com",
  "last_seen": "2019-08-01T20:00:00Z",
  "url": "https://faceb00k.com/malicious",
  "url_hash": "b9793a5bba03f8b1e400a53c0eaf1bf05f038e7daf6ba6383b131db6379d19e6"
}`}
      />
    </DocumentationCodeExample>
  );
};

const CheckDomainRecordsExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" "${ecrimeApiUrl}phishing/domain?domain=example.com&url_limit=25"`}
      />
      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}phishing/domain?domain=example.com&url_limit=25'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json()`}
      />
      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{ "example.com": [ "http://example.hostname.com/path" ] }`}
      />
    </DocumentationCodeExample>
  );
};

const CheckHostnameRecordsExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>

      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" "${ecrimeApiUrl}phishing/fqdn?fqdn=www.example.hostname.com&url_limit=25"`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}phishing/fqdn?fqdn=www.example.hostname.com&url_limit=25'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json()`}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "www.example.hostname.com": [
    "http://example.hostname.com/path"
  ]
}`}
      />
    </DocumentationCodeExample>
  );
};

const UrlStatsExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>

      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" "${ecrimeApiUrl}phishing/url/779b3f6decd0dcd4bf1ca74697f14aef2d28eed3ad25349263a94a4a007b1154"`}
      />

      <h5 className="f_semibold">Python</h5>

      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}phishing/url/779b3f6decd0dcd4bf1ca74697f14aef2d28eed3ad25349263a94a4a007b1154'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json()`}
      />

      <h5 className="f_semibold">Sample response</h5>

      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "created": "2015-11-02T11:02:51Z",
  "detected": "2015-11-02T11:03:08Z",
  "first_inactive": "2015-11-10T18:00:08Z",
  "pushed_to_safebrowsing": "2015-11-02T11:18:07Z",
  "redirect_from": [],
  "redirect_to": [],
  "source": "name",
  "takedown": "2015-11-02T12:00:06Z",
  "url": "http://example.com/t2.php",
  "url_hash": "779b3f6decd0dcd4bf1ca74697f14aef2d28eed3ad25349263a94a4a007b1154"
}`}
      />
    </DocumentationCodeExample>
  );
};

const SubmitUrlExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" "${ecrimeApiUrl}phishing/url/submit" -X POST -d "url=http://phishing.com"`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}phishing/url/submit'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.post(url, headers=headers, data={'url': 'http://phishing.com'})
r.raise_for_status()
r.json()`}
      />
      <h5 className="f_semibold">Sample response</h5>

      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "is_new": true,
  "url": "http://phishing.com",
  "url_hash": "6a18fac7abca126ecd892d4a40e88c3c234e6507399af2c3145713cb60dc40c1"
}`}
      />
    </DocumentationCodeExample>
  );
};

const UrlsExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}phishing/urls`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}phishing/urls'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
r = requests.get(url, headers=headers)
r.raise_for_status()
r.json()`}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  "created": "2019-01-01T09:02:22Z",
  "source": "tbd",
  "url": "http://example.com",
  "url_hash": "5578379eca609b61a1691ff5078a0d7e489806f97f7ac3bc1b3fc3b0350df5ce"
}`}
      />
    </DocumentationCodeExample>
  );
};

const SpecificUrlExample: FC = () => {
  return (
    <DocumentationCodeExample>
      <h5 className="f_semibold">curl</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`curl -H "Authorization: Token <place-your-token-here>" ${ecrimeApiUrl}phishing/url/5578379eca609b61a1691ff5078a0d7e489806f97f7ac3bc1b3fc3b0350df5ce/contents?page=3`}
      />

      <h5 className="f_semibold">Python</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`url = '${ecrimeApiUrl}phishing/url/5578379eca609b61a1691ff5078a0d7e489806f97f7ac3bc1b3fc3b0350df5ce/contents'
headers = {
  'Accept': 'application/json',
  'Authorization': 'Token <place-your-token-here>',
}
params = {'page': 3}
r = requests.get(url, headers=headers, params=params)
r.raise_for_status()
r.json()`}
      />

      <h5 className="f_semibold">Sample response</h5>
      <CodeBlock
        color="grey"
        isCopyable
        text={`{
  [
    {
     "brand": "ERROR-CUSTOM-404",
     "first_seen": "2016-08-31T23:42:28Z",
     "last_seen": "2016-08-31T23:42:28Z",
     "is_active": false
    },
    {
     "brand": "ERROR-CUSTOM-404",
     "first_seen": "2016-09-03T23:59:12Z",
     "last_seen": "2016-09-03T23:59:12Z",
     "is_active": false
    }
  ],
  "page": 1,
  "pages": 1,
  "per_page": 200,
  "total": 2
}`}
      />
    </DocumentationCodeExample>
  );
};
